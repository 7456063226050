<template>
    <Introduction :url="'/brochure/neoma'" :class="'introduction-neoma'">  
        <template #title>
            Neoma Business School
        </template>
        <template #speciality>
            Бизнес
        </template>
        <template #min-duration>
            От 1 года
        </template>
        <template #cost>
            От 14000 €/год
        </template>
        <template #campus>
            Реймс, Руан, Париж
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>