<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #keyPoints>
            <ul>
                <li>
                    3 Кампуса: Реймс, Руан, Париж и 1 виртуальный кампус
                </li>
                <li>
                    9.500 студентов
                </li>
                <li>
                    100 национальностей
                </li>
                <li>
                    30 % иностранных студентов
                </li>
                <li>
                    25% студентов получают помощь (стипендии или альтернанс)
                </li>
                <li>
                    90 студенческих ассоциаций
                </li>
                <li>
                    500 мероприятий ежегодно
                </li>
                <li>
                    360+ академических партнеров
                </li>
                <li>
                    4-е место по размеру нетворкинга выпускников среди французских бизнес-школ
                </li>
                <li>
                    6000 стажировок ежегодно
                </li>
                <li>
                    В среднем каждый студент находит 5 предложений стажировок
                </li>
            </ul>
        </template>
        <template #description>  
            NEOMA Business School - это высшая французская школа бизнеса и менеджмента, основанная в 2013 году после слияния Reims Management School (основана в 1928 году) и Rouen Business School (основана в 1871 году).
            <br>
            NEOMA BS предлагает широкий спектр образовательных программ, такие как степени бакалавра, магистра управления, программы MBA и EMBA, специализированные программы магистратуры, докторские степени в области управления и различные предложения по обучению руководящих работников. Школа неизменно оценивается Financial Times, The Economist и Challenges как одна из лучших бизнес-школ в континентальной Европе и одна из ведущих бизнес-школ во всем мире.
            <br>
            NEOMA является одной из немногих бизнес-школ в мире, имеющих тройную аккредитацию от трех международных организаций по аккредитации: EQUIS, AACSB и AMBA. Школа под руководством Мишеля-Эдуарда Леклерка имеет статус Консульского высшего учебного заведения (EESC). 
            <br>
            Объединенная сеть Alumni Network - одна из ведущих сетей выпускников во Франции, насчитывающая 65.700 выпускников.
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/embed/NupmV6ayZw4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Finance, business, economy
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="bsm">Bachelor in Service Management</option>
                                <option value="cesem">CESEM</option>
                                <option value="idm">TEMA Innovation & Digital Management </option>
                                <option value="mim">Master in Management</option>
                                <option value="msc">Master In Science</option>
                                <option value="imlm">Msс - International master in luxury management</option>
                                <option value="ba">Msc - Business analytics</option> 
                                <option value="bd">Msc - Business Development</option>
                                <option value="scdt">Msc - Supply Chain and Digital Transformation</option>
                                <option value="ei">Msc - Entrepreneurship & Innovation</option>
                                <option value="ism">Msc - International Sales Management</option>
                                <option value="scm">Msc - Supply Chain Management</option>
                                <option value="amifa">Advanced Master International Financial Analysis</option>
                                <option value="cf">Msc - Corporate Finance</option>
                                <option value="fbd">Msc - Finance & Big Data</option>
                                <option value="fiw">Msc - Finance, Investment & Wealth Management </option>
                                <option value="fmt">Msc - Financial Markets & Technologies</option>
                                <option value="cci">Msc - Cultural & Creative Industries</option>
                                <option value="gm">Msc - Global Management</option>
                                <option value="pm">Msc - Project Management</option>
                                <option value="ipd">Msc - International Project Development</option>
                                <option value="dem">Msc - Digital Expertise for Marketing</option>
                                <option value="imbm">Msc - International Marketing & Brand Management</option>
                                <option value="lm">Msc - Luxury Marketing</option>
                                <option value="mfe">Msc - Marketing French Excellence</option>
                                <option value="wg">Msc - Wine & Gastronomy</option>
                                <option value="ccs">AM - Corporate Communication Strategies</option>
                                <option value="mrdm">AM - Marketing Research and Decision Making</option>
                                <option value="mda">AM - Marketing & Data Analysis</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="'/neoma/Neoma_Bachelor_Service_Management.pdf'" v-if="this.program === 'bsm'" id="bsm">
                        <template #title>
                            Бакалавриат - Bachelor in Service Management
                        </template>
                        <template #description>
                           <p>
                                Целью бакалавриата в области управления услугами (BMS) является обучение менеджеров сервисных профессий завтрашнего дня в секторах, претерпевающих серьезные изменения.
                                Это комплексная программа, включающее технологическое и цифровое обучение в сфере услуг и  обслуживания клиентов, а также работа над базовыми навыками управления и предпринимательства.
                                Практическая работа лежит в основе обучения, чтобы подготовить вас к профессии. 
                                Эта программа уникальна тем, что уже с первого года обучения вы не только овладеете основами менеджмента, но и начнете специализироваться  в выбранном вами секторе. Эта специализация обеспечивается двумя известными школами: EC@L NEOMA для профессий розничной торговли, физических услуг и цифровых, пищевых профессий или специализированных (DIY, спорт, косметика, одежда и т. Д.), Ecole FAUCHON для профессий, связанных с гастрономией.
                           </p>
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            6,300 евро/год
                        </template>
                        <template #requirements>
                            французский язык уровня B2, досье, интервью.
                        </template>
                        <template #campus>
                             Руан
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                        <template #salary>
                            28.000-35.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_CESEM.pdf'" v-if="this.program === 'cesem'" id="cesem">
                        <template #title>
                            CESEM
                        </template>
                        <template #description>
                            <p>
                                Как Вам идея провести значительный период времени в разных странах? Вас интересуют международные вопросы, как геополитические, так и экономические? Хотели бы вы работать в многокультурной среде и начать международную карьеру? В этом случае CESEM оправдает ваши ожидания.
                            </p>
                            <p>
                                Благодаря трем международным направлениям (Европа, Америка, Азия), CESEM позволяет вам провести два года во Франции и целых два года в другой стране, получив, таким образом, две степени: одну французскую, а другую - международную степень типа BBA!
                                Это также программа двойных дипломов. Все студенты получают 2 степени бакалавра в признанных бизнес-школах-партнерах.
                            </p>
                        </template>
                        <template #duree>
                            4 года
                        </template>
                        <template #language>
                             английский, французский, язык обучения в партнерском вузе
                        </template>
                        <template #cost>
                            10,800 евро/год
                        </template>
                        <template #requirements>
                            английский язык B2, TAGE MAGE или GMAT или GRE или CAT, досье, интервью.
                        </template>
                        <template #campus>
                            Париж, Партнерский вуз, Реймс
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                        <template #salary>
                            38.000-42.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_Global_BAA.pdf'" v-if="this.program === 'baa'" id="baa">
                        <template #title>Global BBA - Bachelor in Business Administration</template>
                        <template #description>
                            <p>
                                Global BBA в NEOMA Business School - это 4-летний курс (BAC + 4), предназначенный для студентов, интересующихся международной сферой, глобальной экономической средой, управлением проектами или предпринимательством.
                            </p>
                            <p>
                                Программа отличается индивидуальным подходом, сочетающим в себе основы менеджмента, практическое моделирование, погружение в бизнес и международную сферы. В течение 4 лет вы будете проверять свои навыки и знания, выбирать предметы, языки, направления и стажировки, опираться на свой опыт и постепенно строить свой собственный курс, чтобы наилучшим образом подготовить себя к наиболее подходящей для вас карьере.
                            </p>
                            <p>
                                Вы разовьете свое любопытство, понимание межкультурных проблем и способность критически анализировать ситуации и предлагать инновационные решения. Когда вы закончите обучение, эти умения приведут вас к успешной карьере в любой точке мира, работе в мультикультурных командах. Ваши управленческие и межкультурные навыки будут признаны и востребованы компаниями для работы на международных должностях, независимо от того, будет ли находиться ваша компания во Франции или в другом месте.
                            </p>
                        </template>
                        <template #duree>
                            4 года 
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            10,800 евро/год 
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж, Реймс, Руан
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                        <template #salary>
                            44.000 евро/год
                        </template>
                    </Program>
                    
                    <Program :url="'/neoma/Neoma_TEMA.pdf'" v-if="this.program === 'idm'" id="idm">
                        <template #title>TEMA Innovation & Digital Management </template>
                        <template #description>
                            <p>
                                TEMA - программа обучения будущих менеджеров технологиям для поддержки цифровой трансформации и лидерства в инновациях: как в стартапах, так и в крупных международных группах.
                            </p>
                            <p>
                                Гибридные навыки в области управления, цифровых технологий и креативности выпускников TEMA удовлетворяют высокий спрос со стороны бизнеса и способствуют быстрому развитию карьеры.
                            </p>
                            <p>
                                Выпускники TEMA занимают стратегические должности в бизнесе, чаще всего на должностях, связанных с цифровыми технологиями, инновациями и управлением проектами.
                            </p>
                        </template>
                        <template #duree>
                            5 лет
                        </template>
                        <template #language>
                            французский (1-й и 2-й год обучения), английский (3-5 й год обучения)
                        </template>
                        <template #cost>
                             9,700 евро/год 
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                             Париж, Реймс
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                        <template #salary>
                            39.000-45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'mim'" id="mim">
                        <template #title>Master in Management</template>
                        <template #duree>
                            2 или 3 года
                        </template>
                        <template #description>
                           <p>
                                Программа «Магистр менеджмента» направлена ​​на подготовку менеджеров и предпринимателей с высоким потенциалом, способных проявлять инициативу и создавать ценный продукт в многокультурном мире.
                                Присоединившись к программе, вы разовьете общее понимание работы в компании и поймете, как работают вместе ее различные функции.
                           </p>
                           <p>
                               Этот кросс-функциональный подход превратит вас в эксперта в области управления, способного эффективно руководить, развивая при этом свои лидерские качества.
                                Благодаря экспертным трекам, таким как «Сертифицированный финансовый аналитик (CFA®)», «Финансовые рынки и технологии с GARP» или «Консультации и аудит», а также двойным дипломам магистра наук NEOMA или дипломам, предлагаемым школами-партнерами во Франции и других странах, Вы получите подготовку по передовым специальностям (финансы, устойчивое развитие, управление кадрами, маркетинг и т. д.).
                           </p>
                           <p>
                               Программа NEOMA MiM признана на национальном и международном уровнях и предлагает отличные профессиональные перспективы. О ее качестве говорят высокие места в национальных и международных рейтингах.
                           </p>
                           <p>
                               Мастер 1 позволяет вам закрепить свои управленческие навыки и развить кросс-функциональные навыки в управлении. Основные курсы - стратегия, управление операциями, человеческие ресурсы, финансы, маркетинг и т. д. - сочетаются с более практическими знаниями в ​​области управления, переговоров и устойчивого развития.
                           </p>
                           <p>
                               Мастер 2 позволяет развить свой уровень знаний, выбрав специализацию из широкого диапазона вариантов: стажировки, контракты альтернанс с частичной занятостью, двойные или тройные дипломы во Франции или другой стране.
                           </p>
                        </template>
                        <template #language>
                            французский или английский 
                        </template>
                        <template #cost>
                            15,100 евро/год
                        </template>
                        <template #requirements>
                             французский и английский языки уровня B2, GMAT или TAGE MAGE рекомендуется, досье, интервью.
                        </template>
                        <template #campus>
                             Реймс, Руан
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                        <template #salary>
                            44.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'msc'" id="msc">
                        <template #title>Master of Science</template>
                        <template #description>
                            <p>
                               Программы MSc предназначены для студентов, желающих получить специализацию высокого уровня в международной среде.
                            </p>
                        </template>
                        <template #duree>
                            12-15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300 евро/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Реймс, Руан, Париж или кампус-партнер
                        </template>
                        <template #start-time>
                            сентябрь и январь
                        </template>
                        <template #salary>
                            87.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'imlm'" id="imlm">
                        <template #title>Msс - International master in luxury management</template>
                        <template #description>
                            Программа готовит специалистов-менеджеров в индустрии Luxury Goods для работы на руководящих должностях в компаниях, производящих предметы роскоши.
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            21.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Милан и Реймс
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            45.125 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'ba'" id="ba">
                        <template #title>Msc - Business analytics</template>
                        <template #description>
                            <p>
                                Магистр бизнес-аналитики готовит студентов к должностям, где используется принятие решений на основе данных и фактов. Обученные опытными профессионалами и всемирно признанными преподавателями, студенты узнают, как применять новейшие академические знания, аналитические и вычислительные инструменты для принятия деловых решений.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Руан
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'bd'" id="bd">
                        <template #title>Msc - Business Development and Key Account Management</template>
                        
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'scdt'" id="scdt">
                        <template #title>Msc - Supply Chain and Digital Transformation</template>
                        <template #description>
                            <p>
                                Магистратура в области цифровых и инновационных цепочек поставок обучает профессионалов, которые будут разрабатывать и внедрять инновации в рабочую деятельность. Гибкий формат обучения совместим с профессиональной деятельностью.
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            42.472 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'ei'" id="ei">
                        <template #title>Msc - Entrepreneurship & Innovation</template>
                        <template #description>
                            <p>
                                Программа осуществляется при партнерстве с KPMG France. 
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Реймс, Руан
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            38.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'ism'" id="ism">
                        <template #title>Msc - International Sales Management</template>
                        <template #description>
                            <p>
                                Общая цель этой программы магистратуры состоит в том, чтобы подготовить знающих, эффективных профессионалов в области продаж.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Руан
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            38.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'scm'" id="scm">
                        <template #title>Msc - Supply Chain Management </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Руан
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            49.820 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'amifa'" id="amifa">
                        <template #title>Advanced Master International Financial Analysis</template>
                        <template #description>
                            <p>
                                Академическая программа, преподаваемая профессорами по передовым методам в сочетании с практикой, которая проводится через семинары от признанных специалистов из банковской и финансовой сфер.
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            17.300 евро/год
                        </template>
                        <template #requirements>
                            язык B2, досье, интервью
                        </template>
                        <template #campus>
                             Париж, Реймс
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            50.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'cf'" id="cf">
                        <template #title>Msc - Corporate Finance</template>
                        <template #description>
                            <p>
                                Программа позволяет студентам изучить фундаментальные финансовые принципы, приобрести разнообразные навыки и расширить свои знания и методы в области корпоративных финансов, включая слияния и поглощения и частный капитал.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Реймс, Руан
                        </template>
                        <template #start-time>
                            Октябрь, Январь
                        </template>
                        <template #salary>
                            48.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'fbd'" id="fbd">
                        <template #title>Msc - Finance & Big Data</template>
                        <template #description>
                            <p>
                            Навыки, преподаваемые в этой программе, позволяют учащимся стать экспертами в области специализированной финансовой инженерии, применяемой к обработке больших данных.
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                             Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            41.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'fiw'" id="fiw">
                        <template #title>Msc - Finance, Investment & Wealth Management </template>
                        <template #description>
                            <p>
                                Студенты, заинтересованные в получении сертификата CFA, проходят интенсивную подготовку к экзаменам уровня I и II CFA в течение весенних семестров с целью сдать эти экзамены к концу семестра (май-июнь).
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Реймс
                        </template>
                        <template #start-time>
                            Октябрь, Январь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'fmt'" id="fmt">
                        <template #title>Msc - Financial Markets & Technologies</template>
                        <template #description>
                            <p>
                                Учебная программа состоит из двух основных компонентов. Первый - это ультрасовременное обучение управлению финансовыми рисками. Программа готовит студентов к получению сертификата управления финансовыми рисками (FRM). Держатели FRM составляют всемирную сеть профессионалов в крупных банках и компаниях. Второй компонент - это доступ к захватывающей и сложной вселенной новых технологий и их приложений в финансовой отрасли. Студенты развивают свои знания, работая с большими данными, блокчейном, машинным обучением и другими инновациями.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Руан
                        </template>
                        <template #start-time>
                            Октябрь, Январь 
                        </template>
                        <template #salary>
                            40.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'cci'" id="cci">
                        <template #title>Msc - Cultural & Creative Industries</template>
                        <template #description>
                            <p>
                                Студенты встречаются и сотрудничают со многими профессионалами в области искусства (Центр Помпиду, Музей Руана, продюсеры Netflix и Canal +, издательство Editions du Seuil, издательство Editions de la Découverte, Национальный центр кино, Believe Music, Warner, Uzik, mıcroqlıma, Théâtre du Relais, Normandie Impressionisme, Grapes Design и т. д.). Многие из этих профессионалов - выпускники. Программа объединяет теоретические лекции, посвященные искусству, с лекциями высококвалифицированных специалистов и включает в себя оригинальную педагогику с использованием новых технологий, командной работы и творчества.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Руан
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            40.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'gm'" id="gm">
                        <template #title>Msc - Global Management</template>
                        <template #description>
                            <p>
                                Миссия программы - предоставить студентам с разным бэкграундом возможность приобрести знания и опыт в основных управленческих областях и занять руководящие должности в различных социально-экономических контекстах.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Руан
                        </template>
                        <template #start-time>
                            Октябрь, Январь
                        </template>
                        <template #salary>
                            47.100 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'pm'" id="pm">
                        <template #title>Msc - Project Management</template>
                        <template #description>
                            <p>
                                Курс подготовки менеджеров международных проектов в традиционных и инновационных отраслях.  Программа готовит студентов к получению квалификаций PMI / PRINCE2 и SCRUM.
                                Гибкий формат обучения совместим с профессиональной деятельностью.
                            </p>
                        </template>
                        <template #duree>
                            12 месяцев
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            17.100/год
                        </template>
                        <template #requirements>
                            язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            47.100 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'ipd'" id="ipd">
                        <template #title>Msc - International Project Development </template>
                        <template #description>
                            <p>
                                MSc International Project Development предоставляет студентам ключи к развитию их карьеры в области международной торговли путем создания и управления проектом от концепции до реализации. Программа направлена ​​на получение сертификата PRINCE2
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Руан
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'dem'" id="dem">
                        <template #title>Msc - Digital Expertise for Marketing</template>
                        <template #description>
                            <p>
                                Программа Digital Expertise for Marketing предназначена для студентов, которые хотят сделать карьеру в мире цифрового маркетинга. Вы обучитесь работе с основными программами, такими как программное обеспечение E-CRM, программное обеспечение для анализа данных, Adobe Photoshop, Illustrator и т. д.                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Реймс
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'imbm'" id="imbm">
                        <template #title>Msc - International Marketing & Brand Management</template>
                        <template #description>
                            <p>
                                Особенность курса - партнерские отношения с компаниями, включая профессиональные лекции и тематические исследования, основанные на текущих проблемах бизнеса.
                                Некоторые партнерства являются долгосрочными, в то время как другие основываются на годовом соглашении. Примеры партнеров: Nielsen, GFK, Ferrero, Pepsico, Ogilvy, Publicis sapient, Havas media и др.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                             Реймс, Руан
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'lm'" id="lm">
                        <template #title>Msc - Luxury Marketing </template>
                        <template #description>
                            <p>
                                Эта магистратура использует несколько инновационных педагогических методов, применяемых в секторе маркетинга предметов роскоши, при активном участии специалистов по маркетингу и профессоров. Программа сочетает в себе академические лекции с активным обучением, включая практические case studies, посещение компаний, приглашенных гостей и упражнения для совместной работы.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>
                    
                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'mfe'" id="mfe">
                        <template #title>Msc - Marketing French Excellence</template>
                        <template #description>
                            <p>
                                Франция занимает уникальное положение, выделяясь своим культурным разнообразием, научными достижениями, гостеприимством и деловой хваткой. В программе используются примеры из глобальных отраслей, где Франция преуспевает в обучении продвинутой маркетинговой теории и ее техникам. 
                                Получите уникальное представление о мире моды в сотрудничестве с ESMOD Fashion Business.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Руан
                        </template>
                        <template #start-time>
                            Октябрь, Январь
                        </template>
                        <template #salary>
                            42.200 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'wg'" id="wg">
                        <template #title>Msc - Wine & Gastronomy</template>
                        <template #description>
                            <p>
                                Магистерская программа, которая предлагает комплексный, многомерный подход к динамичному сектору маркетинга продуктов питания и напитков в самом сердце одной из самых известных гастрономических стран мира.
                            </p>
                        </template>
                        <template #duree>
                            15 месяцев
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Реймс
                        </template>
                        <template #start-time>
                            Октябрь, Январь
                        </template>
                        <template #salary>
                            42.200 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'css'" id="css">
                        <template #title>Advanced Master Corporate Communication Strategies</template>
                        <template #description>
                            <p>
                                Гибкий формат обучения совместим с профессиональной деятельностью.
                            </p>
                        </template>
                        <template #duree>
                            12 месяцев
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            38.900 евро/год
                        </template>
                    </Program>

                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'mrdm'" id="mrdm">
                        <template #title>Advanced Master Marketing Research and Decision Making</template>
                        
                        <template #duree>
                            12 месяцев
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            43.000 евро/год
                        </template>
                    </Program>
                    
                    <Program :url="'/neoma/Neoma_MS_Msc.pdf'" v-if="this.program === 'mda'" id="mda">
                        <template #title>Advanced Master Marketing and Data Analytics</template>
                        <template #duree>
                            12 месяцев
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            17.300/год
                        </template>
                        <template #requirements>
                            язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>
                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Руан
                </template>
                <template #description>
                    Кампус бизнес-школы NEOMA в Руане - это особенное место площадью 7 гектаров в окружении леса.
                    Кампус оснащен современным учебным оборудованием, таким как trading room, офисами студенческих сообществ, мультимедийной библиотекой, зонами студенческой жизни.
                    Доступ к среде, предлагающей инновационное обучение, можно получить как в кампусе, так и за его пределами: школа предлагает платформу электронного обучения, удаленный доступ к школьным базам данных и документальным материалам (электронные книги, электронные периодические издания, специализированные базы данных и т. Д.).
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/embed/NupmV6ayZw4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Реймс
                </template>
                <template #description>
                    Кампусы NEOMA Business School Reims находятся на двух участках и созданы в современном архитектурном стиле. Они расположены всего в пяти минутах езды на трамвае друг от друга.
                    Кампусы оснащены современным учебным оборудованием: интерактивными досками, комнатами для моделирования, trading room, языковыми лабораториями, офисами студенческого сообщества, мультимедийной библиотекой, зонами студенческой жизни и т. Д.
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/embed/WpqKm0xE-1s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Париж
                </template>
                <template #description>
                    Новый кампус NEOMA Business School в Париже открыл свои двери в начале 2021 учебного года.
                    Он расположен в самом центре столицы, в 13-м округе, в динамичном и типично парижском районе Бьют-о-Кай, недалеко от развитой транспортной инфраструктуры.
                    Он окружен крупными университетскими учреждениями (Университет Париж Пантеон Сорбонна) и объектами, связанными со стартапами, такими как Станция F.
                    Вы будете работать в современном, приятном и модульном кампусе площадью более 6500 м2, состоящем из ярких амфитеатров, классных комнат различных размеров и помещений для индивидуальной или коллективной работы и отдыха.
                    Кампус занимает 5 этажей и может вместить до 1500 человек.
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/embed/e1MMm_utjdo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>